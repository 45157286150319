<template>
  <div class="assets">
    <SimpleHeader></SimpleHeader>
    <div class="summary asset-board ml">
      <div class="head flex align-center">
        <div class="flex1 flex">
          <h3>{{ $t(187) }}</h3>
          <div class="eye cursor" :class="{'is-open':openEye }" @click="openEye=!openEye"></div>
        </div>
        <div class="btx flex">
          <el-button class="fund" @click="$router.push({name: 'Transfer'})">{{ $t(188) }}</el-button>
          <el-button class="fund"  @click="$router.push({name: 'Withdraw'})">{{ $t(189) }}</el-button>
          <el-button class="fund" type="primary" @click="$router.push({name: 'Deposit'})">{{ $t(190) }}</el-button>
          <el-button class="fund" type="text" @click="$router.push({name: 'FinanceRecord'})">{{ $t(191) }} <i class="el-icon-caret-right"></i></el-button>
        </div>
      </div>
      <div class="num">
        <span>{{ openEye ? assets.toUTotal.toFixed(2) : '****' }} USDT</span><em>≈{{  openEye ?(curRate * assets.toUTotal).toFixed(2):'****' }} {{ userCurrency }}</em>
      </div>
    </div>
    <div class="tabs ml">
      <div class="tab-item"
        v-for="item in assets.assets"
        :key="item.category"
        :class="{active:item.category===curCate}"
        @click="itemClick(item)">
        {{ item.categoryName }}
      </div>
    </div>
    <div class="asset-board ml">
      <div class="head flex align-center">
        <div class="flex1">
          <h3>{{ curAssets.categoryName }}</h3>
        </div>
        <div class="btx flex" v-show="curCate === 'C2C'">
          <el-button class="fund" @click="showC2cBuy = true">{{ $t(1040) }}</el-button>
          <el-button class="fund" @click="handleC2cSale">{{ $t(1041) }}</el-button>
        </div>
      </div>
      <div class="flex mb20 mt20 align-center">
        <div class="num flex1">
          <span>{{ openEye ? (curAssets.toUTotal||0).toFixed(2) : '****' }} USDT</span><em>≈{{  openEye ?(curRate * curAssets.toUTotal).toFixed(2):'****' }} {{ userCurrency }}</em>
        </div>
        <div class="search">
          <el-input
            :placeholder="$t(198)"
            prefix-icon="el-icon-search"
            v-model="keywords">
          </el-input>
        </div>
        <div class="hide-small">
          <el-checkbox v-model="hideSmall">{{ $t(195) }}</el-checkbox>
        </div>
      </div>
      <div class="detail">
        <el-table :data="curCoinList" max-height="620">
          <el-table-column
            prop="coin"
            :label="$t(196)">
            <template slot-scope="scope">
              <div class="flex align-center">
                <img :src="coinMsg[scope.row.coin]?coinMsg[scope.row.coin].logoUri:''" alt="" style="width: 20px;height:20px;">
                <span class="ml10">{{ scope.row.coin }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="totalAmount"
            :label="$t(194)">
            <template slot-scope="scope">
                <span>{{ openEye ? scope.row.totalAmount : '****' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="freezenAmount"
            :label="$t(192)">
            <template slot-scope="scope">
                <span>{{ openEye ? scope.row.freezenAmount : '****' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="availableAmount"
            :label="$t(193)">
            <template slot-scope="scope">
                <span>{{ openEye ? scope.row.availableAmount : '****' }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <C2cBuy v-model="showC2cBuy" v-if="showC2cBuy" />
  </div>
</template>

<script>
import { editUserInfo, getAuthInfo } from '@/api'
import SimpleHeader from '@/components/SimpleHeader'
import utils from '@/utils/misc'
import C2cBuy from './components/C2cBuy'
import C2cSale from './components/C2cSale'
export default {
  name: 'Assets',
  components: { C2cBuy, SimpleHeader },
  mixins: [],
  data() {
    return {
      curCate: 'SPOT',
      openEye: true,
      hideSmall: false,
      keywords: '',
      showC2cBuy: false,
      showC2cSale: false
    }
  },
  computed: {
    configObj() {
      return window.configObj
    },
    rules() {
      return {
      }
    },
    countryList() {
      return this.$store.state.countryList
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    assets() { 
      return this.$store.getters.assetsMap
    },
    curAssets() {
      return this.assets.assets[this.curCate] || {}
    },
    currencies() {
      return this.$store.state.currencies
    },
    coinMsg() {
      return this.$store.state.coins
    },
    userCurrency() {
      return this.userInfo.valuationMethod || window.configObj.defaultCurrency 
    },
    curRate() {
      return this.currencies[this.userCurrency] ? this.currencies[this.userCurrency].rate : 1
    },
    curCoinList() {
      const arr = this.curAssets.coins ? [...this.curAssets.coins] : []
      arr.sort((a, b) => b.totalAmount - a.totalAmount)
      if (this.hideSmall) {
        return arr.filter(item => item.totalAmount > 0)
      }
      if (this.keywords !== '') {
        return arr.filter(item => item.coin.indexOf(this.keywords.toUpperCase()) > -1)
      }
      return arr
    }
  },
  beforeMount() {
  },
  methods: {
    itemClick(item) {
      this.curCate = item.category
    },
    handleC2cSale(){
        this.$router.push({name:'C2cSale'})
    }
  }
}
</script>

<style lang="scss" scoped>
.assets{
  padding: 0px 0px 40px 0px;
  background-color: #eef1f5;
  .asset-board{
    background-color: #fff;
    border-radius: 10px;
    padding: 32px 24px;
    margin-top: 20px;
    h3{
      font-size: 20px;
      font-weight: normal;
    }
    .num{
      margin-top: 20px;
      font-size: 24px;
      em{
        margin-left: 6px;
        color: #999;
        font-size: 18px;
      }
    }
  }
  .eye{
    height: 20px;
    width: 20px;
    background: url(../../assets/img/eyes.jpg) no-repeat;
    background-position: 1px 7px;
    background-size: 200%;
    margin-left: 10px;
    &.is-open{
      background-position-x: -19px;
    }
  }
  .search{
    margin-right: 40px;
    .el-input__inner{
      border-radius: 100px;
    }
  }
  .btx {
    .fund{
      height: 36px;
      padding: 10px 20px;
    }
  }
}
.tabs{
  color: #707A8A;
  font-size: 16px;
  padding: 40px 0 20px 0px;
  .tab-item{
    display: inline-block;
    cursor: pointer;
    padding: 8px 24px;
    margin-right: 12px;
    &.active{
      background-color: $primaryColor;
      color: #fff;
      border-radius: 4px;
    }
  }
}
</style>